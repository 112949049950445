import React, { useState } from 'react';
import { css } from '@emotion/react';
import Vimeo from '@u-wave/react-vimeo';
import { MdPause, MdPlayArrow } from 'react-icons/md';

export default function Video({ title, video, description}) {
  const [player, setPlayer] = useState(null);
  const [paused, setPaused] = useState(true);

  return (
    <div
      css={css`
        color: var(--white);
        background-color: var(--black);
        padding: 8rem 0;
      `}
    >
      <div
        css={css`
          display: grid;
          align-items: end;
          grid-template-columns: repeat(12, 1fr);
          gap: var(--gutter);
          row-gap: 0;
          padding: 0 var(--margin);
        `}
      >
        <div
          className="type--large"
          css={css`
            grid-column: span 6;
            margin-bottom: var(--margin);
          `}
        >
          {title}
        </div>
        <div
          css={css`
            position: relative;
            grid-column-start: 3;
            grid-column-end: span 8;
            border: 1px solid var(--white);

            @media (max-width: 900px) {
              grid-column-start: 1;
              grid-column-end: span 12;
            }
          `}
        >
          {video && <Vimeo
            video={video}
            controls={false}
            onReady={(player) => setPlayer(player)}
            responsive
            loop
          />}
        </div>
        <div
          className="type--small-uppercase"
          css={css`
            display: flex;
            justify-content: space-between;
            grid-column-start: 3;
            grid-column-end: span 8;
            padding: 1.8rem 1.5rem 1.5rem;
            border: 1px solid var(--white);
            border-top: 0;
            margin-bottom: 5rem;
            cursor: pointer;

            @media (max-width: 900px) {
              grid-column-start: 1;
              grid-column-end: span 12;
            }
          `}
          onClick={() => {
            player?.getPaused().then((paused) => {
              if (paused) {
                player.play();
                setPaused(false);
              } else {
                player.pause();
                setPaused(true);
              }
            });
          }}
        >
          <div>
            {(paused) ? 'Play' : 'Pause'}
          </div>
          <div
            css={css`
              position: relative;
              top: -0.3rem;
              font-size: 2rem;
              height: 0;
              line-height: 0;
            `}
          >
            {(paused) ? <MdPlayArrow /> : <MdPause />}
          </div>
        </div>
        <div
          css={css`
            grid-column-start: 3;
            grid-column-end: span 8;
            white-space: pre-line;

            @media (max-width: 900px) {
              grid-column-start: 1;
              grid-column-end: span 12;
            }
          `}
        >
          {description}
        </div>
      </div>
    </div>
  )
}
