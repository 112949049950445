import React, { useLayoutEffect } from 'react';
import { useController } from 'react-scroll-parallax';

export default function ParallaxCache() {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { parallaxController } = useController();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      const handler = () => parallaxController.update();
      window.addEventListener('load', handler);
      return () => window.removeEventListener('load', handler);
    }, [parallaxController]);
  }

  return null;
}
