import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import { css } from '@emotion/react';
import Image from 'gatsby-plugin-sanity-image'

export default function Gallery(props) {
  const [end, setEnd] = useState(false);
  const [start, setStart] = useState(true);
  const captionsRef = useRef(null);
  const imagesLoaded = useRef(0);
  const [viewportRef, embla] = useEmblaCarousel({
    align: 0,
    draggable: (typeof window !== 'undefined' && window.innerWidth < 750) ? true : false,
    speed: 7,
  });

 useEffect(() => {
    if (embla) {
      embla.on('select', () => {
        setStart(!embla.canScrollPrev());
        setEnd(!embla.canScrollNext());

        const i = embla.slidesInView(true)[0];
        Array.from(captionsRef.current.children).forEach(el => el.style.opacity = 0);
        captionsRef.current.children[i].style.opacity = 1;
      })
    }
  }, [embla])

  function scrollTo(i) {
    embla?.scrollTo(i);
  }

  function imageLoaded() {
    imagesLoaded.current++;
    if (imagesLoaded.current >= props.images.length) {
      setTimeout(() => {
        if (embla) {
          embla.reInit();
        }
      }, 1200);
    }
  }

  return (
    <div
      className="embla"
      css={css`
        position: relative;

        .embla__viewport {
          overflow: hidden;
          width: 100%;
        }

        .embla__viewport.is-draggable {
          cursor: move;
          cursor: grab;
        }

        .embla__viewport.is-dragging {
          cursor: grabbing;
        }

        .embla__container {
          display: flex;
          user-select: none;
          -webkit-touch-callout: none;
          -khtml-user-select: none;
          -webkit-tap-highlight-color: transparent;
          margin-left: var(--margin);
          will-change: transform;
        }

        .embla__slide {
          position: relative;
          margin-left: 2rem;
          cursor: pointer;

          &.is-selected {
            cursor: auto;
          }

          &.is-selected ~ .is-selected {
            cursor: pointer;
          }
        }
      `}
    >
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          {props.images.length > 0 && props.images
            .map((image, i) => (
              <div
                key={i}
                className="embla__slide"
                css={css`
                  line-height: 0;
                `}
              >
                <img
                  src={image.image?.asset?.gatsbyImageData.images.fallback?.src}
                  css={css`
                    width: auto;
                    height: 70vh;
                    max-height: 50vw;

                    @media (max-width: 900px) {
                      max-height: 60vw;
                    }
                  `}
                  onClick={() => scrollTo(i) }
                  onLoad={() => imageLoaded() }
                />
                <div
                  className="type--extra-small"
                  css={css`
                    margin-top: 2.2rem;
                  `}
                >
                  {i + 1}/{props.images.length}
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div
        ref={captionsRef}
        className="type--body"
        css={css`
          display: grid;
          gap: var(--gutter);
          position: relative;
          background-color: var(--white);
          padding: 0 var(--margin);
          margin-top: 3rem;
        `}
      >
        {props.images.length > 0 && props.images
          .map((image, i) => (
            <div
              key={i}
              css={css`
                grid-row-start: 1;
                grid-column-start: 1;
                grid-column-end: 5;
                opacity: ${(i === 0) ? 1 : 0};
                transition: opacity 1s var(--curve);
                will-change: opacity;
              `}
            >
              {image.caption}
            </div>
          ))
        }
        {props.images.length > 1 && <div
          css={css`
            grid-row-start: 1;
            grid-column-start: 12;
            grid-column-end: 12;
            line-height: 0;
            text-align: right;
            opacity: 1 !important;
          `}
        >
          <img
            css={css`
              padding: 0.7rem 1rem;
              cursor: ${(!start) ? 'pointer' : 'auto'};
              opacity: ${(!start) ? 1 : 0.3};
              transition: opacity 0.6s var(--curve);
            `}
            src={`/images/arrow-left--dark.svg`}
            alt="Previous"
            onClick={() => embla?.scrollPrev() }
          /><img
            css={css`
              padding: 0.7rem 1rem;
              cursor: ${(!end) ? 'pointer' : 'auto'};
              opacity: ${(!end) ? 1 : 0.3};
              transition: opacity 0.6s var(--curve);
            `}
            src={`/images/arrow-right--dark.svg`}
            alt="Next"
            onClick={() => embla?.scrollNext() }
          />
        </div>}
      </div>
    </div>
  );
};
