import React, { useState } from 'react';
import { css } from '@emotion/react';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

export default function Accordion(props) {
  const [open, setOpen] = useState(props.open);

  console.log(props.children);

  return (
    <div
      css={css`
        display: block !important;
        position: relative;
        padding: 0 !important;
        border-bottom: 1px solid var(--black);
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 2rem 2rem 1.5rem 0;
          cursor: ${(props.children) ? 'pointer' : 'auto'};
        `}
        role="button"
        aria-expanded={(open) ? true : false}
        tabIndex="0"
        onClick={() => setOpen(!open)}
        onKeyPress={(e) => {
          e.persist();

          if (e.key === 'Enter')
            setOpen(!open);
        }}
      >
        <div>{props.title}</div>
        {props.children && <div
          css={css`
            position: relative;
            line-height: 1;
          `}
        >
          <span
            css={css`
              position: absolute;
              top: 10px;
              right: 0px;
              width: 12px;
              height: 2px;
              background-color: var(--black);
            `}
          ></span>
          <span
            css={css`
              position: absolute;
              right: 5px;
              top: 5px;
              display: inline-block;
              width: 2px;
              height: 12px;
              background-color: var(--black);
              transform: rotate(${(!open) ? 0 : '90deg'});
              transition: transform 0.6s var(--curve);
            `}
          ></span>
        </div>}
      </div>
      {props.children && <SlideDown
        css={css`
          transition: height 0.6s var(--curve);
        `}
        closed={!open}
      >
        <div
          css={css`
            padding-bottom: 2rem;
          `}
        >{props.children}</div>
      </SlideDown>}
    </div>
  )
}
